import '../estilos/AppRouter.css';
import { Routes, Route, HashRouter } from 'react-router-dom';
import Gantt from './Gantt.js';
import Kanban from './Kanban.js';
import Grilla from './Grilla.js';
import SearchPage from './SearchPage';
//import Proyectos from './Proyectos'
import Agenda from './Agenda'
import Inicio from './Inicio';
import PageNoFound from './PageNoFound'
import Galeria from './Galeria';
import Scheduler from './Scheduler';
import Estadistica from './Estadistica';
import Sidebar from './Sidebar';




export default function AppRouter() {

    return (
                <>
                    <Routes>
                        <Route exact path='/' element={ <Inicio/> }/>
                        <Route exact path='/home' element={ <Inicio /> } />
                        <Route exact path='/search-page' element={ <SearchPage /> } />
                        <Route exact path='/kanban' element={ <Kanban /> } />
                        <Route exact path='/gantt' element={ <Gantt /> } />
                        {/*<Route exact path='/proyectos' element={ <Proyectos /> } />*/}
                        <Route exact path='/agenda' element={ <Agenda /> } />
                        <Route exact path='/grilla' element={ <Grilla /> } />
                        <Route exact path='/galeria' element={ <Galeria /> } />
                        <Route exact path='/scheduler' element={ <Scheduler /> } />
                        <Route exact path='/estadistica' element={ <Estadistica /> } />
                        <Route exact path='/sidebar' element={ <Sidebar /> } />
                        <Route exact path="*" element={ <PageNoFound /> } />
                    </Routes>

                    <div className="AppRouter">
                        
                    </div>
                </>
    );
}