import '../estilos/FormSesion.css'
import swal from 'sweetalert';

export default function FormSesion() {

    return (
        
        <div className='FormSesion'>
            
            <form>
                <h3 id='titulo'>Inicio de Sesión</h3>
                <input type='text' id='usuario' placeholder="Usuario:"/>
                <input type='password' id='password' placeholder="Password:"/>
                <hr/>
            </form>

            <div id="btn-inciar-sesion">
                <button className="btn btn-success btn-sm" onClick={ () => {
                    swal("Ups!", "En esta versión no está disponible el inicio de sesión!", "error");
                }}>Iniciar Sesión</button>
            </div>

        </div>
    )
}