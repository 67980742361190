import '../estilos/Agenda.css'
import Navbar from "./Navbar";


export default function Agenda() {
    
    const contenido = document.querySelector('#contenido');
    

    const traer = () => {
        fetch(' misdatos.txt')
        .then (datos => datos.text())
            .then (datos => {
                console.log(datos)
                contenido.innerHTML = `$(datos)`
                contenido.innerHTML = `<h3>Chau</h3>`
            })
    }

    return (
            <div id='Principal-Agenda'>
                <h3>Agenda</h3>
                
                <div className='container my-5 text-center'>
                    <button className='btn btn-danger w-100' onClick={traer}>Obtener</button>
                    <div className='mt-5' id='contenido'>

                    </div>
                </div>
                
                <Navbar />
            </div>
    )

}

