//import '../estilos/Inicio.css'
import Clock from './Clock';
import FondoPrincipal from './FondoPrincipal';
import FormSesion from './FormSesion';
import LogoNeoElectronics from './LogoNeoElectronics';
import Navbar from './Navbar';


const Inicio = () => {
    
    console.log("Componente inicio")

    return (
                <>
                    <FondoPrincipal />
                    <Clock />
                    <FormSesion />
                    <LogoNeoElectronics />
                    <Navbar />
                </>
    )

}

export default Inicio;


/*
function clock() {
      var o_horas = document.getElementById('hour');
      var o_minutos = document.getElementById('minutes');
      var o_segundos = document.getElementById('seconds');
      var o_ampm = document.getElementById('ampm');

      var h = new Date().getHours();
      var m = new Date().getMinutes();
      var s = new Date().getSeconds();

      var ampm = '--';

      if (h > 12) {
          h = h - 12;
          ampm = 'PM';
      } else {
          ampm = 'AM';
      }

      h = (h < 10) ? '0' + h : h;
      m = (m < 10) ? '0' + m : m;
      s = (s < 10) ? '0' + s : s;

      o_horas.innerHTML = h;
      o_minutos.innerHTML = m;
      o_segundos.innerHTML = s;
      o_ampm.innerHTML = ampm;
  }

  //var intervalo = setInterval(clock, 1000);

  */