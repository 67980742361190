import '../estilos/Navbar.css'
import { Link } from 'react-router-dom';

export default function Navbar() {

    return (

        <div className = "App-Nav">
            <nav className="navbar navbar-expand-lg navbar-dark bg-primary shadow-lg rounded">
                <div className="container-fluid">

                    <a className="navbar-brand" href="/">www.neoElectronics.com.ar <span id='version'>(v20220518.1500)</span></a>
               
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link id='miLink' to='/home'><button className='btn bg-primary' aria-current='page' href=''>Home</button></Link>
                            </li>
                            
                            <div className="dropdown">
                                <button className="btn dropdown-toggle bg-primary" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">Proyectos</button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <Link className='dropdown-item' id='miLink' to='/scheduler'><li><button className='dropdown-item' aria-current='page' href=''>Agenda</button></li></Link>
                                    <Link className='dropdown-item' id='miLink' to='/kanban'><li><button className='dropdown-item' aria-current='page' href=''>Kanban</button></li></Link>
                                    <Link className='dropdown-item' id='miLink' to='/grilla'><li><button className='dropdown-item' aria-current='page' href=''>Excel</button></li></Link>
                                    <Link className='dropdown-item' id='miLink' to='/gantt'><li><button className='dropdown-item' aria-current='page' href=''>Gantt</button></li></Link>
                                    <Link className='dropdown-item' id='miLink' to='/scheduler'><li><button className='dropdown-item' aria-current='page' href=''>Scheduler</button></li></Link>
                                    <Link className='dropdown-item' id='miLink' to='/estadistica'><li><button className='dropdown-item' aria-current='page' href=''>Estadisticas</button></li></Link>
                                    <Link className='dropdown-item' id='miLink' to='/sidebar'><li><button className='dropdown-item' aria-current='page' href=''>Sidebar</button></li></Link>
                                </ul>
                            </div>

                            <li className="nav-item">
                                <Link id='miLink' to='/Galeria'><button className="btn bg-primary" aria-current='page' href=''>Galería</button></Link>
                            </li>

                            <li className="nav-item">
                                <Link id='miLink' target="_blank" to='./form_contacto/form_contacto.html'><button className="btn bg-primary" aria-current="page" href='./form_contacto/form_contacto.html' target="_blank">Contacto</button></Link>
                            </li>

                            <form className="d-flex">
                                <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search"/>
                                <Link id='miLink' to='/search-page'><button className="btn btn-primary" type="submit">Search</button></Link>
                                <Link id='miLink' to='/sesion'><button className="btn btn-success ms-2" type="submit">Ingresar</button></Link>
                            </form>

                        </ul>
                    </div>
                </div>
            </nav>

        </div>

    )

}