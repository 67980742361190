import * as React from 'react';
import { ScheduleComponent, Day, Week, WorkWeek, Month, Agenda, Inject, Resize, DragAndDrop } from '@syncfusion/ej2-react-schedule';
import '../estilos/Scheduler.css';
import { extend } from '@syncfusion/ej2-base';
import { SampleBase } from './sample-base';
//import * as dataSource from './datasourceScheduler.json';
import zooEventsData from './datasourceScheduler.json';
import {scheduleData, timelineData, eventsData, employeeEventData, leaveData, recurrenceData, webinarData, doctorsEventData, resourceData, timelineResourceData, resourceTeamData, resourceConferenceData, holidayData, birthdayData, companyData, personalData, doctorData, resAirlinesData, fifaEventsData, roomData, headerRowData, hospitalData, waitingList, blockData, resourceSampleData, quickInfoTemplateData} from './datasourceScheduler.json';
import Navbar from './Navbar';
/**
 * Schedule local data sample
 */
export default class Scheduler extends SampleBase {
    scheduleObj;
    //data = extend([], dataSource.zooEventsData, null, true);
    data = extend([], zooEventsData, null, true);
    //data = zooEventsData;
    onEventRendered(args) {
        let categoryColor = args.data.CategoryColor;
        if (!args.element || !categoryColor) {
            return;
        }
        if (this.scheduleObj.currentView === 'Agenda') {
            args.element.firstChild.style.borderLeftColor = categoryColor;
        }
        else {
            args.element.style.backgroundColor = categoryColor;
        }
    }
    render() {
        return (<div className='schedule-control-section2'>
        <div className='col-lg-12 control-section'>
          <div className='control-wrapper'>
            <ScheduleComponent width='100%' height='650px' selectedDate={new Date(2021, 1, 15)} ref={t => this.scheduleObj = t} eventSettings={{ dataSource: this.data }} eventRendered={this.onEventRendered.bind(this)}>
              <Inject services={[Day, Week, WorkWeek, Month, Agenda, Resize, DragAndDrop]}/>
            </ScheduleComponent>
          </div>
        </div>
        <Navbar />
      </div>);
    }
}
