import '../estilos/Grilla.css'
import { OrderDetails } from '../componentes/data2.js'
import { ColumnsDirective, ColumnDirective, RangesDirective, RangeDirective, SheetsDirective, SheetDirective, SpreadsheetComponent, ScrollSettings } from '@syncfusion/ej2-react-spreadsheet'
import Navbar from './Navbar'


export default function Grilla( { vScreenClean, vModulo } ) {

        return (
                <>
                    
                    <div id="spreadsheet">
                        <div className="Grilla">
                            <SpreadsheetComponent className='miSheet'
                                    allowOpen={true}
                                    openUrl= "https://ej2services.syncfusion.com/production/web-services/api/spreadsheet/open"
                                    allowSave={true}
                                    saveUrl= "https://ej2services.syncfusion.com/production/web-services/api/spreadsheet/save"
                                    isFinite={false} >
                                <SheetsDirective>
                                    <SheetDirective>
                                        <RangesDirective>
                                        <RangeDirective dataSource={OrderDetails}></RangeDirective>
                                        </RangesDirective>
                                        <ColumnsDirective>
                                            <ColumnDirective width={160}></ColumnDirective>
                                            <ColumnDirective width={160}></ColumnDirective>
                                            <ColumnDirective width={160}></ColumnDirective>
                                            <ColumnDirective width={160}></ColumnDirective>
                                        </ColumnsDirective>
                                    </SheetDirective>
                                </SheetsDirective>
                            </SpreadsheetComponent>
                        </div>
                        <Navbar />
                    </div>
                </>
        )

}