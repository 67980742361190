import Navbar from './Navbar'

export default function SearchPage() {

    return (
                <>
                    <br/><br/><br/>
                    <h3>Search Page</h3>
                    <Navbar />
                </>
    )

}