import '../estilos/LogoNeoElectronics.css'
import miLogo from '../Disenio-Logo-Claudio-150x150.png';

export default function LogoNeoElectronics() {
    return (
        <>
            <div id="divimg">
                <img id='logo' src={miLogo} alt="Logo" />
            </div>
        </>
    )
}