import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
//import App from '../src/componentes/App.js';
import AppRouter from '../src/componentes/AppRouter.js';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { registerLicense } from '@syncfusion/ej2-base';

registerLicense('ORg4AjUWIQA/Gnt2VVhhQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkBjUX5dc3NURWRaVkM=');


const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
    <React.StrictMode>
        <HashRouter>
            <AppRouter />
        </HashRouter>
    </React.StrictMode>
);