import '../estilos/Clock.css';

export default function Clock(props) {

    return (
            <div id="clock">
                <h2 className="h2">Hora Actual</h2>
                <div id="time">
                    <div><span id="hour">--</span><span>Hora</span></div>
                    <div><span id="minutes">--</span><span>Minutos</span></div>
                    <div><span id="seconds">--</span><span>Segundos</span></div>
                    <div><span id="ampm">--</span></div>
                </div>
            </div>
    )

}



function clock() {
      var o_horas = document.getElementById('hour');
      var o_minutos = document.getElementById('minutes');
      var o_segundos = document.getElementById('seconds');
      var o_ampm = document.getElementById('ampm');

      var h = new Date().getHours();
      var m = new Date().getMinutes();
      var s = new Date().getSeconds();

      var ampm = '--';

      if (h > 12) {
          h = h - 12;
          ampm = 'PM';
      } else {
          ampm = 'AM';
      }

      h = (h < 10) ? '0' + h : h;
      m = (m < 10) ? '0' + m : m;
      s = (s < 10) ? '0' + s : s;

      o_horas.innerHTML = h;
      o_minutos.innerHTML = m;
      o_segundos.innerHTML = s;
      o_ampm.innerHTML = ampm;
  }

  var intervalo = setInterval(clock, 1000);
