import '../estilos/Gantt.css';
import * as React from 'react';
import { GanttComponent, EditDialogFieldsDirective, DayMarkers, EditDialogFieldDirective, Inject, Edit, Selection, Toolbar, ColumnsDirective, ColumnDirective, EventMarkersDirective, EventMarkerDirective } from '@syncfusion/ej2-react-gantt';
import { editingData, editingResources } from './data.js';
import { SampleBase } from './sample-base.js';
import Navbar from './Navbar';



export default class Gantt extends SampleBase {


    taskFields = {
        id: 'TaskID',
        name: 'TaskName',
        startDate: 'StartDate',
        endDate: 'EndDate',
        duration: 'Duration',
        progress: 'Progress',
        dependency: 'Predecessor',
        child: 'subtasks',
        notes: 'info',
        resourceInfo: 'resources'
    };
    resourceFields = {
        id: 'resourceId',
        name: 'resourceName'
    };
    editSettings = {
        allowAdding: true,
        allowEditing: true,
        allowDeleting: true,
        allowTaskbarEditing: true,
        allowSelection:true,
        showDeleteConfirmDialog: true
    };
    splitterSettings = {
        columnIndex: 2
    };
    projectStartDate = new Date('03/25/2019');
    projectEndDate = new Date('07/28/2019');
    gridLines = 'Both';
    toolbar = ['Add', 'Edit', 'Update', 'Delete', 'Cancel', 'ExpandAll', 'CollapseAll', 'Indent', 'Outdent'];
    timelineSettings = {
        topTier: {
            unit: 'Week',
            format: 'MMM dd, y',
        },
        bottomTier: {
            unit: 'Day',
        },
    };
    labelSettings = {
        leftLabel: 'TaskName',
        rightLabel: 'resources'
    };
    eventMarkerDay1 = new Date('4/17/2019');
    eventMarkerDay2 = new Date('5/3/2019');
    eventMarkerDay3 = new Date('6/7/2019');
    eventMarkerDay4 = new Date('7/16/2019');

    render() {

        return (

            <div className='Gantt'>
                
                <div className='control-section'>
                    <GanttComponent className='Gantt2' id='Editing' dataSource={editingData} dateFormat={'MMM dd, y'} treeColumnIndex={1} allowSelection={true} showColumnMenu={false} highlightWeekends={true} allowUnscheduledTasks={true} projectStartDate={this.projectStartDate} projectEndDate={this.projectEndDate} taskFields={this.taskFields} timelineSettings={this.timelineSettings} labelSettings={this.labelSettings} splitterSettings={this.splitterSettings} height='1660px' editSettings={this.editSettings} gridLines={this.gridLines} toolbar={this.toolbar} resourceFields={this.resourceFields} resources={editingResources}>
                        <ColumnsDirective>
                            <ColumnDirective field='TaskID' width='80'></ColumnDirective>
                            <ColumnDirective field='TaskName' headerText='Job Name' width='250' clipMode='EllipsisWithTooltip'></ColumnDirective>
                            <ColumnDirective field='StartDate'></ColumnDirective>
                          <ColumnDirective field='Duration'></ColumnDirective>
                          <ColumnDirective field='Progress'></ColumnDirective>
                          <ColumnDirective field='Predecessor'></ColumnDirective>
                        </ColumnsDirective>
                        <EditDialogFieldsDirective>
                            <EditDialogFieldDirective type='General' headerText='General'></EditDialogFieldDirective>
                            <EditDialogFieldDirective type='Dependency'></EditDialogFieldDirective>
                            <EditDialogFieldDirective type='Resources'></EditDialogFieldDirective>
                            <EditDialogFieldDirective type='Notes'></EditDialogFieldDirective>
                        </EditDialogFieldsDirective>
                        <EventMarkersDirective>
                            <EventMarkerDirective day={this.eventMarkerDay1} label='Project approval and kick-off'></EventMarkerDirective>
                            <EventMarkerDirective day={this.eventMarkerDay2} label='Foundation inspection'></EventMarkerDirective>
                            <EventMarkerDirective day={this.eventMarkerDay3} label='Site manager inspection'></EventMarkerDirective>
                            <EventMarkerDirective day={this.eventMarkerDay4} label='Property handover and sign-off'></EventMarkerDirective>
                        </EventMarkersDirective>
                        <Inject services={[Edit, Selection, Toolbar, DayMarkers]}/>
                    </GanttComponent>
                </div>
                <Navbar />
            </div>
        );
    }
}

//render(<Editing />, document.getElementById('sample'));