import '../estilos/FondoPrincipal.css'

export default function FondoPrincipal() {
    return (
        <>
            {/*
                <br/><br/><br/>
                <h3>Fondo Principal</h3>
            */}
        </>
    )
}